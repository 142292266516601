import React from "react"
import { Container, Row, Col, Image } from "react-bootstrap";
import PhoneIcon from "../../src/assets/images/brand/phone.svg";
import EmailIcon from "../../src/assets/images/brand/email.svg";
import LocationIcon from "../../src/assets/images/brand/location.svg";
import ClockIcon from "../../src/assets/images/brand/clock.svg";
const Contact = () => {
    return (
        <Container className="left-content">
            <Row className="full-height text-center d-flex align-items-center justify-content-center">
                <Col sm="12" md="12">
                    <h3 className="light">Bizimle iletişime geçin!</h3>
                    <p>Tüm soru ve görüşleriniz için bizimle iletişime geçebiliriniz.</p>
                    <Row className="text-center">
                        <Col sm="12" md="6" className="brand-item p-4" as="a" href="tel:0850 241 0 640">
                            <Image className="brand-icon" src={PhoneIcon} />
                            <h5 className="mt-4 light">0850 241 0 640</h5>
                        </Col>
                        <Col sm="12" md="6" className="brand-item p-4" as="a" href="mailto:info@sonic.com.tr">
                            <Image className="brand-icon" src={EmailIcon} />
                            <h5 className="mt-4 light">info@sonic.com.tr</h5>
                        </Col>
                    </Row>
                    <Row className="text-center">
                        <Col sm="12" md="6" className="brand-item p-4" as="a" target="blank" href="https://www.google.com/maps/dir/41.070539,28.8014116/sonic+guvenlik/@41.0698961,28.800791,16z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x14caa579f9b3bdc7:0xd9a8231336742e0f!2m2!1d28.8087052!2d41.0708629">
                            <Image className="brand-icon" src={LocationIcon} />
                            <h6 className="mt-4 light">İkitelli OSB Mahallesi, Giyim Sanatkarları Sitesi 2A Blok <br />
                                Kat:6 Kapı No: 601/1 Başakşehir / İstanbul</h6>
                        </Col>
                        <Col sm="12" md="6" className="brand-item p-4">
                            <Image className="brand-icon" src={ClockIcon} />
                            <h6 className="mt-4 light">Pazartesi - Cuma : 09:00 - 18:30<br />Cumartesi : 09:00 - 13:30</h6>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
};
export default Contact;