import * as React from "react";
import { Routes, Route } from "react-router-dom";
import "./assets/css/custom.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import  Page from "./Page"
const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Page />} />
      {/* <Route path="*" element={<NotFound />} /> */}
    </Routes>
  )
}
export default App