import React, { useRef, useState} from "react";
import emailjs from '@emailjs/browser';
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import ThanksTicket from "./Thanks";

const Ticket = () => {

    const [ticket, setTicket] = useState(false)
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_py15moo', 'sonic-sender', form.current, 'SilpKTuzPi28Q3bYU')
            .then((result) => {
                setTicket(true)
            }, (error) => {
            });
    };
    return (
        <Container>
            {ticket === false ? (
                <Container className="left-content">
                    <Row className="full-height text-center d-flex align-items-center justify-content-center">
                        <Col>
                            <h3 className="light">Sistemlerinizle ilgili yaşadığınız tüm sorunlar için<br />talep oluşturarak
                                ekiplerimizden destek alabilirsiniz.</h3>
                            <Form ref={form} onSubmit={sendEmail}>
                                <Row>
                                    <Col xs="12" sm="12" lg="6" xl="6" className="p-1" >
                                        <Form.Control required name="firstname" type="text" placeholder="Adınız" />
                                    </Col>
                                    <Col xs="12" sm="12" lg="6" xl="6" className="p-1">
                                        <Form.Control required name="lastname" type="text" placeholder="Soyadınız" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" sm="12" lg="6" xl="6" className="p-1">
                                        <Form.Control required name="email" type="email" placeholder="E-posta adresiniz" />
                                    </Col>
                                    <Col xs="12" sm="12" lg="6" xl="6" className="p-1">
                                        <Form.Control required name="phone" type="phone" placeholder="Telefon numaranız" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" sm="12" lg="6" xl="6" className="p-1">
                                        <Form.Control required name="company" type="text" placeholder="Şirket" />
                                    </Col>
                                    <Col xs="12" sm="12" lg="6" xl="6" className="p-1">
                                        <Form.Select required name="category" aria-label="Destek Kategorisi">
                                            <option>Destek Kategorisi</option>
                                            <option value="Bilgisayar Sistemleri">Bilgisayar Sistemleri</option>
                                            <option value="Kamera Sistemleri">Kamera Sistemleri</option>
                                            <option value="Hırsız İhbar Sistemleri">Hırsız İhbar Sistemleri</option>
                                            <option value="Yangın Alarm Sistemleri">Yangın Alarm Sistemleri</option>
                                            <option value="Geçiş Kontrol Sistemleri">Geçiş Kontrol Sistemleri</option>
                                            <option value="Network Sistemleri">Network Sistemleri</option>
                                            <option value="Personel Takip Sistemleri">Personel Takip Sistemleri</option>
                                            <option value="Lisanslamalar">Lisanslamalar</option>
                                        </Form.Select>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" sm="12" lg="12" xl="12" className="p-1">
                                        <Form.Control required name="message" type="text" placeholder="Lütfen talebinizi birkaç kelime ile anlatınız" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" sm="12" lg="12" xl="12" className="text-center p-1">
                                        <Button type="submit" className="red-solid-btn">
                                            Talep Oluştur
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            ) : (
                <Container className="text-center">
                    <ThanksTicket />
                    <Button className="red-solid-btn" onClick={() => setTicket(false)}>Yeni Bir Talep Oluştur</Button>
                </Container>
            )
            }
        </Container>
    )
};
export default Ticket;