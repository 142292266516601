import React from "react";
import { Tab, Tabs, Image } from "react-bootstrap";
import SupportImage from "../../src/assets/images/brand/support.svg";
import ContactImage from "../../src/assets/images/brand/contact.svg";
import Ticket from './Ticket';
import Contact from './Contact';
export const TicketTab = () => {
    return (
        <div>
            <p><Image className="brand-image" src={SupportImage} /> DESTEK TALEBİ OLUŞTURUN</p>
        </div>
    )
}
export const ContactTab = () => {
    return (
        <div>
            <p><Image className="brand-image" src={ContactImage} />BİZİMLE İLETİŞİME GEÇİN</p>
        </div>
    )
}

const TabContent = () => {
    return (
        <Tabs fill variant="pills" className="mb-3 mobile-tab">
            <Tab eventKey="ticket" title={<TicketTab />}>
                <Ticket />
            </Tab>
            <Tab eventKey="contact" title={<ContactTab />}>
                <Contact />
            </Tab>
        </Tabs>
    )
}
export default TabContent;