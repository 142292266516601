import React from "react"
import { Image } from "react-bootstrap"
import WhiteLogo from "../assets/images/brand/white-logo.svg"
const Branding = () => {
    return (
        <div className="brand">
            <Image className="logo" src={WhiteLogo} />
        </div>
    )
}
export default Branding;