import React from "react";
import { Container, Row, Col} from "react-bootstrap";
import Typewriter from "typewriter-effect";
const calltoAction = () => {
    return (
        <Container>
            <Row className="vh-100 align-items-center">
                <Col className="text-left">
                    <h1 className="text-white">
                        <Typewriter
                            options={{
                                strings: ['Yenilenen websitemiz ile,<br />çok yakında burdayız.', 'We are here very soon,<br/>with our renewed website.', 'your<br/> technology<br/> provider'],
                                autoStart: true,
                                loop: true,
                                changeDelay: 1,
                                pauseFor: 3000,
                                delay: 1,
                                deleteAll: 10000,
                                cursor: `_>`,
                                deleteSpeed: 5
                            }}
                        />
                    </h1>
                </Col>
            </Row>
        </Container>
    )
}
export default calltoAction;
