import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Branding from "./components/Branding";
import CalltoActions from "./components/CallToActions";
import Tabs from "./components/Tabs";
const Page = () => {
    return (
        <>
            <Container fluid>
                <Row>
                    <Branding />
                    <Col sm={12} md={12} lg={6} xl={6} xxl={6} className="vh-100 primary-bg">
                        <CalltoActions />
                    </Col>
                    <Col sm={12} md={12} lg={6} xl={6} xxl={6} className="white-bg">
                        <Tabs />
                    </Col>
                </Row>
            </Container>
        </>
    )
};
export default Page;