import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
// import ThanksImage from "../src/assets/images/brand/thanks.svg";

const Thanks = () => {
    return (
        <Container>
            <Row>
                <Col className="m-5 text-center">
                    {/* <Image className="brand-image" src={ThanksImage}/> */}
                    <h3 className="light">
                        Talebiniz<br/>Başarıyla Oluşturulmuştur.
                    </h3>
                    <p>Ekiplerimiz en kısa sürede talebinizle ilgili size geri dönüş sağlayacaklardır.</p>
                </Col>
            </Row>
        </Container>
    )
};
export default Thanks;